<template>
  <div class="zt-component-main">
    <div class="component-label" style="display: inline-block;">
      <slot></slot>
      {{ widget.componentName }}：
    </div>

    <!--    <div style="display: inline-block; word-wrap: break-word;word-break: normal;">{{ computedWidgetCustomContent }}</div>-->
    <div style="display: inline-block; word-wrap: break-word;word-break: normal;">{{ radioAnswer }}</div>

    <div flex-row-flex-start-flex-start class="tag-spacing">
      <span class="component-label">{{ combineName }}：</span>
      <div flex-row-flex-start-center>
        <template v-for="(item,index) in combineAnswer">
          <el-image class="item-of-image" style="height: 200px;" :src="normalizeImageUrl(item)" alt="" :preview-src-list="[normalizeImageUrl(item)]"></el-image>
        </template>
      </div>
    </div>
  </div>
</template>

<script type="text/ecmascript-6">

import ComponentMixin from './component-mixin'
import {isEmpty}      from 'lodash'

export default {
  extends   : ComponentMixin,
  mixins    : [],
  components: {},
  name      : 'ZtPhone',
  props     : {
    className: { type: String, default: '' },
    widget   : { type: Object, required: true },
  },
  data() {
    return {
      combineName  : '居住证明',
      combineAnswer: [],
      radioAnswer  : '',
    }
  },
  watch   : {
    // someObject: { handler: function (val, oldVal) { /* ... */ }, deep: true, immediate: true },
  },
  computed: {},
  methods : {
    //
  },
  created() {
    // document.documentElement.scrollTop = 0
  },
  destroyed() {},

  // mounted() {
  //   if (isEmpty(this.widget)) {throw new Error('widget is empty')}
  //   if (this.widget && Reflect.has(this.widget, 'answer') && this.widget.answer) {
  //
  //     let find = this.widget.optionList.find(item =>{
  //       return item.optionValue == this.widget.answer
  //     })
  //
  //     if(!isEmpty(find)){
  //       console.log('【ZtRadioCombineUploadImg:61】',`find = `,find);
  //     }
  //   }
  // },

  mounted() {
    if (isEmpty(this.widget)) {throw new Error('widget is empty')}
    if (this.widget && Reflect.has(this.widget, 'answer') && this.widget.answer) {
      let find = this.widget.optionList.find(item => item.optionValue == this.widget.answer)
      if (!isEmpty(find)) {
        this.radioAnswer = find.optionName
      }
      ///
      let combineAnswer = this.widget.combineComponent.combineAnswer
      if (combineAnswer) {
        this.combineAnswer = combineAnswer.split(',')
      }
    }
  },
}
</script>

<style scoped lang="scss" rel="stylesheet/scss"> @import "component.scss";
@import "component.scss";
</style>
